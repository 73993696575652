/* -----------------------------------navbar ----------------------------------------------*/
.lg-styl {
  height: 52px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.sm-styl {
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  z-index: 99;
}
.textsmall {
  font-size: 12px;
}
.n-logo {
  border-radius: 30px;
  /* border: 2px solid #b6b6b4; */
}
.logoborder {
}
.height-see {
  height: 450px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .ipadstyl {
    margin-left: 180px;
  }
  .lg-styl {
    display: none;
  }
  .sm-styl {
    display: block;
  }
  .table-stly {
    overflow-x: auto;
  }
}
.hovereff:hover {
  color: black;
}
.hovereff {
  color: white;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0px 0 0;
}

.tag {
  width: auto;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 10px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
}

.tag-close-icon {
  display: block;
  width: 20px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
/* ---------------------header */
.searchinp {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  height: 50px;

  outline: none;
  position: relative;
  /* -moz-box-shadow: 10px 10px 5px #888;
  -webkit-box-shadow: 10px 10px 5px #888;
  box-shadow: 10px 10px 5px #888; */
  padding-left: 25px;
  font-size: 12px;
}
.footer-styl {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* ------------card------------------------ */
.dealbtn-styl {
  margin-top: 15px;
  /* margin-left: 90px; */
  justify-content: center;
  align-items: center;
}
.redcolor {
  color: #be1f1d;
}
.sell-btn {
  color: #626500;
  text-decoration: underline;
}

.card1 {
  /*float: left;*/
  background-color: #ffffff;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 25.333%;
  flex-grow: 0;
  flex-shrink: 0;
}
.row1 {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.slide-container {
  width: 30%;
}
.fullwidth {
  width: 90%;
  height: 70%;
}
.border-corner {
  background: linear-gradient(to right, white 2px, transparent 2px) 0 0,
    linear-gradient(to right, #d9d9d9 2px, transparent 2px) 0 100%,
    linear-gradient(to left, white 2px, transparent 2px) 100% 0,
    linear-gradient(to left, #d9d9d9 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, white 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, white 2px, transparent 2px) 100% 0,
    linear-gradient(to top, #d9d9d9 2px, transparent 2px) 0 100%,
    linear-gradient(to top, #d9d9d9 2px, transparent 2px) 100% 100%;
  padding-bottom: 10px;
  padding-left: 10px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

/* media query */
@media screen and (max-width: 767px) {
  .lg-styl {
    display: none;
  }
  .sm-styl {
    display: block;
  }
  .card1 {
    /*float: left;*/
    background-color: #ffffff;
    margin-bottom: 1rem;
    border: 0;
    margin-left: 15px;
    flex-basis: 75.333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .img-mbstyl {
    /* margin-left: 30px; */
  }
}
.height-styl {
  height: 700px;
}

/* --------------------------------------------------------payment ------------------------------------------------*/

.AppWrapper {
  width: 500px;
  height: 400px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 white;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  /* border-top: 1px solid black; */
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: black;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid lightgray;
  margin-right: 5px;
  margin-top: 5px;
  padding-left: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: gray;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.ErrorMessage {
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
.logotop {
  margin-top: 5px;
}

.rotate {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
