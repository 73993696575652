@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-styl {
  /* @apply block bg-white  uppercase orgcolor btn-shadow font-bold shadow-2xl  text-xs  px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150; */
  @apply block shadow-styl bg-white uppercase btncolor font-bold text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150;
}
.input-styl {
  @apply placeholder-gray-600 text-gray-600 bg-white rounded-md text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-gray-300;
  /* @apply border rounded-md border-gray-900 border-solid; */
}
.center-styl {
  @apply flex  content-center items-center justify-center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-shadow {
  position: relative;
  -webkit-box-shadow: 1px 3px 32px -5px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 1px 3px 32px -5px rgba(0, 0, 0, 0.45);
  box-shadow: 1px 3px 32px -5px rgba(0, 0, 0, 0.45);
}
.shadow-styl {
  -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.75);
}
.btncolor {
  color: #ff8439;
}
.orgcolor {
  /* color: #ffa64d; */
  color: #77dd77;
}
.bg-primary {
  background-color: #77dd77;
}
.orgback {
  /* background-color: #ffa64d; */
  background-color: #77dd77;
}
/* -------scroll bar *-------------------------/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar {
  height: 10px; /* height of horizontal scrollbar ← You're missing this */
  width: 10px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}
input[type='file'] {
  display: none;
}
